<template>
  <form action="/">
    <van-search
      v-model="searchKey"
      show-action
      :placeholder="PAGELANG.searchkeywords"
      :action-text="SYSLANG.quxiao"
      @search="onSearch"
      @cancel="onCancel"
    />
  </form>
  <div v-if="history.length > 0" class="search-history">
    <div class="title">
      {{ PAGELANG.searchhistory }}
      <i class="iconfont icon-delete" @click="deleteHistory" />
    </div>
    <div class="list">
      <span
        v-for="(item, index) in history"
        :key="index"
        class="item"
        @click="onSearch(item)"
        >{{ item }}</span
      >
    </div>
  </div>
</template>

<script>
import { getCookie, setCookie } from "../util";
import { Base64 } from "js-base64";
import { showConfirmDialog } from "vant";

export default {
  data() {
    return {
      searchKey: "",
      history: [],
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.goods;
    let shcookie = getCookie("SearchHistory");
    //console.log(decodeURIComponent(Base64.decode(shcookie)));
    if (shcookie) {
      this.history = JSON.parse(decodeURIComponent(Base64.decode(shcookie)));
    }
    console.log(this.history);
  },
  methods: {
    onSearch(val) {
      console.log(val);
      if (this.history.indexOf(val) == -1) {
        this.history.push(val);
      }

      let str = encodeURIComponent(JSON.stringify(this.history));
      //console.log(str, Base64.encode(str));
      setCookie("SearchHistory", Base64.encode(str), 90);

      this.$router.push({
        path: "/product",
        query: {
          SearchKey: val,
        },
      });
    },
    onCancel() {
      this.$router.replace({ path: "/product" });
    },
    deleteHistory() {
      showConfirmDialog({
        title: this.PAGELANG.dialogtitle,
        message: this.PAGELANG.deletehistory,
      })
        .then(() => {
          this.history = [];
          let str = encodeURIComponent(JSON.stringify(this.history));
          setCookie("SearchHistory", Base64.encode(str));
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.search-history {
  padding: var(--van-search-padding);
}

.search-history .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.search-history .title .iconfont {
  color: #777;
}

.search-history .list {
  display: flex;
  flex-wrap: wrap;
}

.search-history .list .item {
  display: inline-block;
  padding: 0 10px;
  line-height: 24px;
  font-size: 12px;
  color: #777;
  border-radius: 13px;
  border: 1px solid #ddd;
  margin-right: 10px;
  margin-top: 8px;
}
</style>